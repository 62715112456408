import { Link, useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../slices/LoginSlice";
// import { Context } from "../../context/Context";
import "./Login.css";
import worldImg from "../../assests/contact/contactField.png";
import logo from "../../assests/contact/logo.png";
import logos from "../../assests/contact/logo1.png";
import date from "../../assests/contact/date.png";
import BasicExample from "../../component/Navbar/BasicExample";
import { unwrapResult } from "@reduxjs/toolkit";

export default function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const [ApiFailed, setApiFailed] = useState(false);

  const navigate = useNavigate();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [errors, setErrors] = useState(false);
  const userRef = useRef();
  const passwordRef = useRef();

  const dispatch = useDispatch();


  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      LoginUser({
        email: userRef.current.value,
        password: passwordRef.current.value,
      })
    ).then(unwrapResult).then(() => {
      navigate("/abstractUpdated")
    }).catch((err) => {
      console.log(err)
      setApiFailed(true)
    })

  };

  return (
    <>
      <BasicExample />
      <div className="row login d-flex justify-content-center align-items-center">
        <div className="col-lg-6 col-12 login_mobile_tab">
          <div className="container-fluid col-10">
            <div className="row d-flex align-items-center">
              <div className="col-lg-4 col-md-6 col-6 ">
                <h3 className="organizede ">ORGANIZED BY</h3>
                <img
                  src={logo}
                  alt=""
                  className="img-fluid contact_logos mt-5 center-block d-block mx-lg-0 mx-auto "
                />
              </div>
              <div className="col-lg-3 col-md-3 col-3"></div>
              <div className="col-lg-4 col-md-3 col-3">
                <h3 className="organizede">
                  {" "}
                  <span style={{ visibility: "hidden" }}>s</span>
                </h3>
                <img
                  src={logos}
                  alt=""
                  className="img-fluid contact_logos mt-5 center-block d-block mx-lg-0 mx-auto"
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12 mt-4">
                <img
                  src={worldImg}
                  alt=""
                  className="img-fluid center-block d-block mx-auto world_data"
                />
                <h2 className="mt-4 bangok_heads">BALI, INDONESIA</h2>
                <h2 className="mt-4 bangok_heads">VENUE:- BINTANG BALI RESORT</h2>
                <div className="mt-5 ">
                  <img
                    src={date}
                    alt=""
                    className="img-fluid date_home center-block d-block mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12 Login_background">
          <div>
            <div>
              <h2 className="login_head">Login</h2>
              <div className="d-flex mt-5">
                <Link to="/login" className="linkALL activeLoginLink">
                  <h3 className="userAcive">User</h3>
                </Link>
                <Link to="/adminlogin" className="linkALL mx-5">
                  <h3 className="userAcive">Admin</h3>
                </Link>
              </div>

              <p className="login_para mt-5">
                Welcome to the World Dentist Conference login page. Please enter
                your login credentials to access your account:
              </p>
            </div>

            <form className="loginForm" onSubmit={handleSubmit}>
              <div className="mt-5">
                <input
                  className="loginInput"
                  ref={userRef}
                  type="text"
                  placeholder="Enter your email..."
                />
              </div>

              <div className="mt-5 d-flex">
                <input
                  className="loginInput"
                  type={passwordType}
                  ref={passwordRef}
                  placeholder="Enter your password..."
                />
                <div onClick={togglePassword} className="showbar_icon">
                  {passwordType === "password" ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </div>
              </div>
              <button className="loginButton mt-5" type="submit">
                Login
              </button>
            </form>
            <h3 className="userAcive text-center mt-3">OR</h3>
            <Link to="/registration">
              <button className="loginButton mt-3">Register</button>
            </Link>
            {ApiFailed && (
              <p className="errorMessage">Email or password is not valid</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import Abstract from "./pages/Abstract";
import Committe from "./pages/Committe";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Sample from "./pages/Sample";
import Speakers from "./pages/Speakers";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./pages/Login/AdminLogin";
import RegisterDetail from "./pages/Register/RegisterDetail";
import UserDetail from "./pages/admin/UserDetail";

import React from "react";

import AbstractUpdated from "./pages/AbstractUpdated";
import Accopany from "./pages/admin/Accopany";
import ApplyAwards from "./pages/Register/ApplyAwards";
import DetailAwards from "./pages/admin/DetailAwards";
import SampleFile from "./pages/Register/SampleFile";
import AwardDetail from "./pages/Award/AwardDetail";
import Contact from "./pages/Contact/Contact";
import LoginSample from "./pages/Login/LoginSample";

import RegistrationDetail from "./pages/admin/RegistrationDetail";
import { useSelector } from "react-redux";
import AccompanyPerson from "./pages/Register/AccompanyPerson";

const App = () => {
  const admindata = useSelector((state) => state.LoginSlice.AdminData);
  const { details } = admindata;
  console.log(details);

  const ProtectedRoute = ({ children }) => {
    if (!details) {
      return <Navigate to="/adminlogin" />;
    }

    return children;
  };
  const userData = useSelector((state) => state.LoginSlice.LoginData);
  const userdetails = userData[0]?.details;

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/acc" element={<AccompanyPerson />} />
          <Route path="/abstract" element={<Abstract />} />
          <Route path="/commitee" element={<Committe />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/sample" element={<Sample />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/awardDetail" element={<AwardDetail />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/registerDetail" element={<RegisterDetail />} />
          <Route path="/sampleLogin" element={<LoginSample />} />
          <Route
            path="/abstractUpdated"
            element={userdetails ? <AbstractUpdated /> : <Login />}
          />
          <Route path="/applyaward" element={<ApplyAwards />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/registrationDetail" element={<RegistrationDetail />} />

          <Route path="/file" element={<SampleFile />} />

          <Route path="/adminDetails">
            <Route
              index
              element={
                <ProtectedRoute>
                  <UserDetail />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/accompany">
            <Route
              index
              element={
                <ProtectedRoute>
                  <Accopany />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/detailAwards">
            <Route
              index
              element={
                <ProtectedRoute>
                  <DetailAwards />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

import React from "react";
import Footer from "../component/Footer/Footer";
import "./Speaker.css";
import BasicExample from "../component/Navbar/BasicExample";
import seven from "../assests/commitee/seven.png";
import eight from "../assests/commitee/eight.png";
import nine from "../assests/commitee/nine.png";

const Speakers = () => {
  return (
    <div>
      <BasicExample />

      <div className="container-fluid col-lg-10 col-12">
        <div className="row mt-lg-5 mt-5">
          <div className="col-lg-12 col-12">
            <h2 className="text_fields mt-5">Keynote Lecture</h2>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-4">
            <img
              src={seven}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={eight}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div> */}
        </div>
      </div>
      <div className="container-fluid col-lg-10 col-12">
        <div className="row mt-lg-5 mt-5">
          <div className="col-lg-12 col-12">
            <h2 className="text_fields mt-lg-5 mt-4">GUEST LECTURE</h2>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-4">
            <img
              src={seven}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={eight}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div> */}
        </div>
      </div>
      <div className="container-fluid col-lg-10 col-12">
        <div className="row mt-lg-5 mt-5">
          <div className="col-lg-12 col-12">
            <h2 className="text_fields mt-lg-5 mt-4">RISING STARS</h2>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12 mt-lg-4 mt-4">
            <img
              src={seven}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={eight}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-4">
            <img
              src={nine}
              className="img-fluid center-block d-block mx-auto"
            />
          </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Speakers;

import React from "react";
import "./Abstract.css";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Group from "../assests/abstract/Group.png";
import Ourmission from "../assests/abstract/ourmission.png";
import grops from '../assests/abstract/grops.png'
import Footer from "../component/Footer/Footer";
import BasicExample from "../component/Navbar/BasicExample";

const Abstract = () => {
  return (
    <div>
      <BasicExample />
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="abstract_passage">
            <h2 className="abstract_head">Abstract Submission</h2>
          </div>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center align-items-center">
        <div className="col-lg-12 col-12">
          <h2 className="scientific_head">
            Instructions For Speakers Presentation
          </h2>
        </div>
      </div>

      <div className="row mt-5 mt-lg-0 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
        <div className="col-lg-6 col-12">
          <p className="scient_para mt-4 mt-lg-0">
            <ol>
              <li>
                The abstract form for speakers should be filled in the website
              </li>
              <li>
                Kindly ensure to enter the participant's active WhatsApp number
                in the form as a group will be created for regular updates and
                swifter communication.
              </li>
              <li>
                Attachment of the following documents (all in PDF Format) is
                mandatory:
              </li>

              <ul className="mt-2 mb-2">
                <li>
                  <b>Passport size photo</b>
                </li>
                <li>
                  <b>Curriculum Vitae</b>
                </li>
                <li>
                  <b>Brief Biography</b>
                </li>
                <li>
                  <b>Abstract with title</b>
                </li>
                <li>
                  <b>Registration payment receipt</b>
                </li>
              </ul>

              <li>The Abstract should contain not more than 250 words.</li>
              <li>
                You will receive an abstract submission confirmation within 5
                days.
              </li>
              <li>Duration for the Speaker presentations are as follows:</li>
              <li>The Abstract should contain not more than 250 words.</li>

              <ul className="mt-2">
                <li>
                  <b>FOR KEYNOTE LECTURE:</b> 40 minutes Presentation + 5
                  minutes Discussion
                </li>
                <li>
                  <b>FOR GUEST LECTURE:</b> 25 minutes Presentation + 5 minutes
                  Discussion
                </li>
                <li>
                  <b>FOR RISING STARS:</b> 15 minutes Presentation + 5 minutes
                </li>
                <li>
                  The Last Date for Abstract Submission is st 1 December, 11.00 am.

                </li>
              </ul>
            </ol>
          </p>
        </div>
        <div className="col-lg-6 col-12 mt-3 mt-lg-0">
          <div className="width_max">
            <img src={Group} className="img-fluid group_abstract " />
          </div>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center align-items-center">
        <div className="col-lg-12 col-12">
          <h2 className="scientific_head mt-5">
            Instructions For Paper Presentations For Student
          </h2>
        </div>
      </div>

      <div className="row   d-flex justify-content-center align-items-center">
        <div className="mt-5 mt-lg-4 col-lg-6">
          <div className="width_max1">
            <img src={Ourmission} className="img-fluid group_abstract " />
          </div>
        </div>
        <div className="mt-5 mt-lg-0 col-lg-6">
          <p className="scient_para mt-4 mt-lg-0">
            <ol>
              <li>
                The abstract form for students should be filled in the website
              </li>
              <li>
                Kindly ensure to enter the participant's active WhatsApp number in the form
                as a group will be created for regular updates and swifter communication.
              </li>
              <li>
                The abstract with title should contain not more than 250 words
              </li>
              <li>
                The abstract with title should be attached in PDF format
              </li>
              <li>
                Attachment of the following documents (all in PDF Format) is
                mandatory:
                <ul className="mt-2 mb-2">
                  <li>Passport size photo</li>
                  <li>Abstract with title
                  </li>
                  <li>Registration payment receipt</li>
                </ul>
              </li>
              <li>You will receive an abstract submission confirmation within 5 days.
              </li>
              <li>
                Not more than one registered participant will be allowed to present a paper
              </li>
              <li>
                The participant's name and college name should be mentioned in the first slide
                of the paper presentation.
              </li>
              <li>
                The duration for paper presentation will be 8 minutes + 2 minutes for discussion.
              </li>
              <li>Co-Authors/Guides will not receive certificates for participation.
              </li>
              <li>
                Participants do not have to submit their final power point presentations before
                the conference.
              </li>
              <li>
                The overall best poster presentation in each subject category
                will be awarded with a certificate & a momento.{" "}
              </li>
              <li>
                The overall best paper presentation in each subject category will be awarded
                with a certificate & a momento.
              </li>
              <li>
                The Last Date for Abstract Submission is 5 December, 11.00 am.
              </li>
            </ol>
            <span className="bold_para">
              A REGISTERED STUDENT CAN PRESENT EITHER PAPER OR POSTER ONLY
            </span>
          </p>
        </div>
      </div>

      <div className="row mt-5 d-flex justify-content-center align-items-center">
        <div className="col-lg-12 col-12 mt-5">
          <h2 className="scientific_head">
            Instructions For Poster Presentation For Student
          </h2>
        </div>
      </div>

      <div className="row mt-5 mt-lg-0 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
        <div className="col-lg-6 col-12">
          <p className="scient_para mt-4 mt-lg-0">
            <ol>
              <li>
                Abstract submission is not required. However, the poster submission form
                should be filled and the final poster should be attached in the website.
              </li>
              <li>
                Kindly ensure to enter the participant's active WhatsApp number in the
                form as a group will be created for regular updates and swifter communication.
              </li>
              <li>
                Attachment of the following documents (all in PDF Format) is
                mandatory:
                <ul className="mt-2 mb-2">
                  <li>Passport size photo</li>
                  <li>Final Poster</li>
                  <li>Registration payment receipt</li>
                </ul>
              </li>
              <li>The E-Poster will have to be self-explanatory.</li>
              <li>
                No specific dimension is required. Any landscape/portrait in PDF
                format is acceptable.
              </li>
              <li>
                The posters will not have to be presented by the participant.
              </li>
              <li>
                The participant's name and college name should be mentioned in
                the poster.
              </li>
              <li>
                Not more than one registered participant will be allowed to
                present a Poster.
              </li>
              <li>
                The overall best poster presentation in each subject category
                will be awarded with a certificate & a momento.
              </li>
              <li>
                You will receive Poster submission confirmation within 5 days.
              </li>
              <li>
                The Last Date for Final Poster Submission is 5 December, 11.00 am.
                <br />
              </li>
              <li>Poster number will not be provided.</li>
            </ol>
            <span className="bold_para">
              A REGISTERED STUDENT CAN PRESENT EITHER PAPER OR POSTER ONLY
            </span>
          </p>
        </div>
        <div className="col-lg-6 col-12 mt-3 mt-lg-0">
          <div className="width_max">
            <img src={grops} className="img-fluid group_abstract " />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Abstract;

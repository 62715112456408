import React, { useContext } from "react";
import "./Home.css";
import Registration from "../assests/Home/Rectangle12.png";
import Abstract from "../assests/Home/Rectangle8.png";
import Dental from "../assests/Home/Rectangle11.png";
import About from "../assests/Home/about.jpg";
import Chairman from "../assests/Home/chairman.jpeg";
import Attend from "../assests/Home/why.jpg";
import Footer from "../component/Footer/Footer";
// import Sample from "./Sample";
import BasicExample from "../component/Navbar/BasicExample";
import { Link } from "react-router-dom";
import worldImg from "../assests/contact/contactField.png";
import logo from "../assests/contact/logo.png";
import logos from "../assests/contact/logo1.png";
import date from "../assests/contact/date.png";

const Home = () => {
  return (
    <>
      <BasicExample />
      <div className="center">

        <div className="home_banner pb-5">
          <div className="container-fluid col-10">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-6 col-6">
                <h3 className="organizede">ORGANIZED BY</h3>
                <img
                  src={logo}
                  className="img-fluid contact_logos mt-5 center-block d-block mx-lg-0 mx-auto "
                />
              </div>
              <div className="col-lg-3 col-md-3 col-3"></div>
              <div className="col-lg-3 col-md-3 col-3">
                <h3 className="organizede">
                  {" "}
                  <span style={{ visibility: "hidden" }}>s</span>
                </h3>
                <img
                  src={logos}
                  className="img-fluid contact_logos mt-5 right-block d-block mx-lg-5 ms-auto "
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12 mt-4">
                <img
                  src={worldImg}
                  className="img-fluid center-block d-block mx-auto world_data"
                />
                <h2 className="mt-4 bangok_heads">Bali, Indonesia</h2>
                <h2 className="mt-4 bangok_heads">Venue:- Bintang Bali Resort
                </h2>
                <div className="mt-5">
                  <img
                    src={date}
                    className="img-fluid date_home center-block d-block mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid col-10">
          <div className="row mt-5">
            <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
              <Link to="/registerDetail" className="linkALL">
                <div className="Registration_img">
                  <img
                    src={Registration}
                    className="img-fluid rectangle_images"
                  />
                  <h1 className="registration_head text-center">Registration</h1>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
              <Link to="/abstractUpdated" className="linkALL">
                <div className="Registration_img">
                  <img src={Abstract} className="img-fluid rectangle_images" />
                  <h1 className="registration_head text-center">
                    Abstract Submission{" "}
                  </h1>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-5">
              <Link to="/applyaward" className="linkALL">
                <div className="Registration_img">
                  <img src={Dental} className="img-fluid rectangle_images" />
                  <h1 className="registration_head text-center">
                    Apply For Dental Awards
                  </h1>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 col-md-12 col-12">
            <img src={About} className="img-fluid d-block center-block mx-auto about_images" />
          </div>
          <div className="col-lg-6 col-12  p-lg-0 p-5">
            <h3 className="about_head">About us</h3>
            <p className="about_para mt-4">
              The goal of the WDC 2024 is to present the latest and the best in
              scientific research and clinic cases in all specialties in
              Dentistry. It will also provide an international dais for dentists
              from all over the world to exchange ideas, knowledge and encourage
              networking with similar specialists. World-renowned experts from
              different countries will present Guest Lectures, and Keynote
              Lectures on a wide range of topics that are of particular interest
              to the dental speciality.
              <br />
              <br />
              This year we have introduced a special presentation category, Rising
              Star for dentists who have less than five years of experience after
              completion of Post Graduation. In addition to this there will be a
              competitive paper and poster presentation open to dental students
              (Under Graduates, Post Graduates).
            </p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center align-items-center ">
          <div className="col-lg-6 col-12 col-md-12">
            <h3 className="chairman_head">Organising Chairman Message</h3>
            <p className="chairman_para mt-4">
              <span className="boldHeading">
                "Welcome to the World Dental Conference 2024!"
              </span>
              <br />
              <br />
              I am delighted to invite you to join us in the beautiful island of Bali, Indonesia,
              on December 14th and 15th, 2024, for the 6th World Dental Conference. This
              year's theme, 'Embracing Innovations in Dentistry,' reflects our commitment
              to staying at the forefront of advancements in dentistry. This premier
              international event promises to be an unforgettable experience, bringing
              together the global dental community to share knowledge, innovations and
              best practices.
              <br />
              <br />
              In addition to scientific interactions, as always, this WDC also promises you an
              unforgettable experience of fun and entertainment. The Presidential Banquet
              will be arranged by the poolside with a Grand live performance of the
              internationally acclaimed Balinese Fire dance and a well-crafted cultural show.
              This is why WDC today, is the most awaited International Dental Conference of
              2024.
              <br />
              <br />
              The WDC 2024 will feature:
              <ul>
                <li>
                  Keynote and Guest lectures by world-renowned experts.
                </li>
                <li>
                  Cutting-edge research presentations
                </li>
                <li>
                  Interactive workshops and hands
                </li>
                <li>
                  on training- Exhibition of latest technologies and products
                </li>
                <li>
                  Excellence in Dentistry Awards, recognizing and acknowledging dentists for
                  their achievements and maintaining high standards of excellence.
                </li>
              </ul>


              Bali, with its rich cultural heritage and breathtaking natural beauty, provides
              the perfect backdrop for our conference.
              <br />
              <br />
              I look forward to welcoming you to this incredible destination and sharing two
              days of scientific interaction and fun. So register today and we shall meet in
              Bali soon.
            </p>
          </div>
          <div className="mt-5 col-lg-6 col-md-12 col-12">
            <img src={Chairman} className="img-fluid chairman_images" />
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center align-items-center flex-column-reverse flex-lg-row">
          <div className="mt-5 col-lg-6 col-12">
            <img src={Attend} className="img-fluid d-block center-block mx-auto about_images" />
          </div>
          <div className="mt-5 col-lg-6 col-md-12 co-12 ml-5 ml-lg-0">
            <h3 className="about_head ">Why Attend this Conference </h3>
            <ul className="mt-4">
              <li className="attendPara">
                The WDC 2024 will keep you abreast with all the latest research
                developments and treatment techniques in Dentistry
              </li>
              <li className="attendPara mt-3">
                The WDC 2024 will expose you to some of the best keynote speakers in
                dentistry from around the world
              </li>
              <li className="attendPara mt-3">
                You will receive Information about the latest equipment and dental
                materials from traders at various stalls.

              </li>
              <li className="attendPara mt-3">
                The most economical international dental conference in recent times.

              </li>
              <li className="attendPara mt-3">
                The WDC 2024 will serve as the best platform to network and socialise
                with like-minded professionals.
              </li>
              <li className="attendPara mt-3">
                Special Awards Night is conducted to recognize and acknowledge dentist
                all over the world associated with Dentistry.
              </li>
              <li className="attendPara mt-3">
                Special free paper and poster sessions for Undergraduate Students,
                Dentists and Post Graduate Students.
              </li>
              <li className="attendPara mt-3">
                Special free paper and poster sessions for Undergraduate Students,
                Dentists and Post Graduate Students.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import {
  Specialities,
  Qualificationist,
  Years,
  PostGraduatedesignationer as designationer,
} from "../../../utils/constants";
const Graduation = ({ getGraduationDetails, errorMsg1 }) => {
  const [Roles, setRoles] = useState("Undergraduate Student");
  const [Quality, setQuality] = useState("");
  const [Qualifications, setQualifications] = useState("");
  const [designations, setDesignations] = useState("");
  const [Specialitiies, setSpecialitiies] = useState("");

  const onOptionChange = (e) => {
    setRoles(e.target.value);
  };

  useEffect(() => {
    getGraduationDetails({
      Roles,
      Quality: Quality.value,
      Qualifications: Qualifications.value,
      designations: designations.value,
      Specialitiies: Specialitiies.value,
    });
  }, [Roles, Quality, Qualifications, designations, Specialitiies])



  return (
    <div>
      <div className="row mt-5">
        <div className="col-lg-12 col-12 mt-4">
          <h2 className=" head_general">
            Undergraduate Student/Postgraduate Student & Dentists*
          </h2>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12 col-12 ">
          <div className="form_register center-block d-block mx-auto d-flex">
            <Form.Check
              type="radio"
              name="Attender"
              value="Undergraduate Student"
              label="Undergraduate Student"
              id="regular"
              checked={Roles === "Undergraduate Student"}
              onChange={onOptionChange}
            />
            <Form.Check
              type="radio"
              name="Postgraduate Student & Dentists"
              value="Postgraduate Student & Dentists"
              id="medium"
              label="Postgraduate Student & Dentists"
              checked={Roles === "Postgraduate Student & Dentists"}
              onChange={onOptionChange}
              className="mx-4"
            />
          </div>
        </div>
      </div>

      {Roles === "Undergraduate Student" ? (
        <>
          <div className="row mt-5">
            <div className="col-lg-6 mt-3 mt-lg-0 ">
              <div className="form_register1 right-block d-block ms-auto ">
                <Select
                  placeholder="Qualification..."
                  options={Years}
                  isSearchable
                  label={Quality}
                  defaultValue={Quality}
                  required
                  onChange={setQuality}
                />
                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-lg-6 mt-3 mt-lg-0 ">
              <div className="form_register1 right-block d-block ms-auto ">
                <Select
                  placeholder="Qualification..."
                  options={Qualificationist}
                  isSearchable
                  label={Qualifications}
                  defaultValue={Qualifications}
                  required
                  onChange={setQualifications}
                />
                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>

            <div className="col-lg-6 mt-3 mt-lg-0">
              <div className="form_register1">
                <Select
                  placeholder="Designation..."
                  options={designationer}
                  isSearchable
                  label={designations}
                  defaultValue={designations}
                  required
                  onChange={setDesignations}
                />
                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>

            <div className="col-lg-6 mt-3 mt-lg-0">
              <div className="form_register1 right-block d-block ms-auto">
                <Select
                  placeholder="Speciality..."
                  options={Specialities}
                  isSearchable
                  label={Specialitiies}
                  defaultValue={Specialitiies}
                  required
                  onChange={setSpecialitiies}
                />
                <p className="error-message1 mt-3">{errorMsg1}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Graduation;
